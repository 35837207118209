@use '../../../styles/_global-variables.scss' as global;

.footer_wrapper {
    background-color: var(--clr_trans_90);
}

.footer_pre {
    display: flex;
    align-items: center;
    padding: 0 16px;
    max-width: 2144px;
    margin: 0 auto;

    .imgM {
        height: 28px;
    }
    .imgS {
        height: 22px;
    }
    .imgL {
        height: 30px;
    }
    a {
        color: var(--clr_trans_10);
        opacity: 0.8;
        transition: opacity 0.15s ease-in-out 0s;
        font-size: var(--body_l);
        font-weight: 700;
    }
    a:hover {
        opacity: 1;
    }
    &__container {
        padding: 48px 0;
        display: grid;
        grid-template-columns: 1fr auto auto;
        grid-template-rows: 1fr;
        grid-template-areas: 'trescat logos links';
        gap: 32px;
        align-content: center;
        width: 100%;
    }
    &__container div {
        height: 30px;
        align-items: center;
        //gap: 32px;
        & > a {
            margin: 0 16px;
            &:last-child {
                margin: 0 0 0 16px;
            }
            &:first-child {
                margin: 0 16px 0 0;
            }
        }
    }
    &__3cat {
        display: flex;
        grid-area: trescat;
    }
    &__logos {
        display: flex;
        justify-content: flex-end;
        grid-area: logos;
    }
    &__links {
        grid-area: links;
        display: flex;
        justify-content: flex-end;
    }
}

.footer_main__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 'corpo corpo contacte llengua troba';
    gap: 20px;
    padding: 0 16px;
    max-width: 2144px;
    margin: 32px auto;

    h3 span {
        font-size: var(--body_m);
        color: var(--clr_trans_0);
    }
    h3 {
        font-size: var(--body_m);
        color: var(--clr_trans_0);
        margin-bottom: 8px;
        font-weight: 400;
        line-height: 1.5;
        border-bottom: 1px solid var(--clr_trans_70);
        padding-bottom: 8px;
    }
    ul {
        list-style: none;
        margin-left: 0;
    }
    a {
        display: inline-block;
        margin-bottom: 8px;
        box-sizing: border-box;
    }
    span {
        color: var(--clr_trans_30);
        font-size: var(--body_s);
        opacity: 0.8;
        line-height: 1.5;
        transition: opacity 0.15s ease-in-out 0s;
    }
    a:hover span {
        opacity: 1;
        border-bottom: 1px solid;
    }
}

.footer_main .js_desplegable ul:not(.open) {
    display: block;
}

.footer_main {
    &__corpo {
        grid-area: corpo;
    }
    &__corpo ul {
        -moz-column-count: 2;
        column-count: 2;
        -moz-column-gap: 20px;
        column-gap: 20px;
    }
    &__contacte {
        grid-area: contacte;
    }
    &__llengua {
        grid-area: llengua;
    }
    &__troba {
        grid-area: troba;
    }
    &__boto a {
        border: 1px solid;
        border-radius: 7px;
        padding-inline: 8px;
        height: 34px;
        color: var(--clr_trans_0);
        opacity: 0.8;
        transition: opacity 0.15s ease-in-out 0s;
        display: inline-flex;
        align-items: center;
        width: 100%;
        &.footer_main__boto_ajuda {
            border: none;
            background-color: #4d4d4d;
            margin-top: 36px;
        }
    }
    &__boto div {
        font-size: var(--body_xs);
        line-height: 0;
    }
    &__boto a:hover {
        color: var(--clr_trans_0);
        opacity: 1;
    }
    &__ico__smartphone {
        padding-right: 2px;
    }
    &__ico__smarttv {
        padding: 0 6px 0 0;
        width: 27px;
    }
    &__container span.drowpdown_icona {
        display: none;
    }
}

.footer_post {
    &__container {
        //border-top: 1px solid var(--clr_trans_70);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 16px 0 48px;
        max-width: 2144px;
        margin: 16px 16px 0;
        &:before {
            content: '';
            flex-basis: 100%;
            display: block;
            width: 100%;
            height: 1px;
            background: var(--clr_trans_70);
            margin-bottom: 16px;
        }
    }
    &__container div {
        display: flex;
        a {
            margin-right: 16px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__container a {
        display: inline-block;
        margin-bottom: 8px;
    }
    &__container span {
        color: var(--clr_trans_10);
        font-size: var(--body_s);
        opacity: 0.8;
        line-height: 1.5;
        transition: opacity 0.15s ease-in-out 0s;
    }

    &__container a:hover span {
        opacity: 1;
        border-bottom: 1px solid;
    }
    &__copy {
        font-size: var(--body_s);
        line-height: 1.5;
        color: var(--clr_trans_40);
    }
}

@media (min-width: 1440px) {
    .footer_pre,
    .footer_main__container {
        padding-inline: 56px;
    }
    .footer_post__container {
        padding-inline: 56px;
        margin: 32px auto 0;
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .footer_pre,
    .footer_main__container {
        padding-inline: 34px;
    }
    .footer_post__container {
        margin-inline: 34px;
    }
}

@media (max-width: 1023px) {
    .footer_post__container {
        display: block;
    }
    .footer_main__troba {
        display: inline-flex;
        gap: 0 16px;
        align-items: center;
        margin-top: 10px;
    }
    .footer_main__troba h3 {
        border: none;
        line-height: 1;
        margin: 0;
        padding: 0;
        white-space: nowrap;
    }
    .footer_main__troba ul {
        display: inline-flex;
        gap: 0 16px;
        margin: 0;
        width: 100%;
    }
    .footer_main__troba a {
        margin: 0;
    }
    .footer_main__boto:last-child {
        margin-left: auto;
        a {
            width: auto;
            white-space: nowrap;
        }
    }
    .footer_main__boto a {
        padding-inline: 10px;
        width: 190px;
        &.footer_main__boto_ajuda {
            margin-top: 0;
        }
    }
    .footer_main__boto div {
        font-size: var(--body_xs);
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .footer_pre {
        padding: 0 32px;
    }
    .footer_pre__container {
        padding-block: 44px;
        gap: 28px;
    }
    .footer_pre__container div {
        //gap: 28px;
        & > a {
            margin: 0 14px;
            &:last-child {
                margin: 0 0 0 14px;
            }
        }
    }
    .footer_main__container {
        grid-template-rows: auto auto;
        grid-template-areas: 'corpo corpo contacte llengua' 'troba troba troba troba';
        padding-block: 40px;
        padding: 0 32px;
    }
    .footer_post__container {
        margin-inline: 32px;
    }
}

@media (max-width: 767px) {
    .footer_pre__container .footer_pre__links {
        a {
            font-size: var(--body_m);
            margin: 0 8px;
        }
    }

    .footer_pre__container div {
        justify-content: center;
        & > a {
            margin: 0 12px;
        }
    }
    .footer_pre__container {
        padding-block: 40px;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas: 'trescat' 'logos' 'links';
        gap: 32px;
    }
    .footer_main__container {
        gap: 0;
        grid-template-rows: auto auto auto auto;
        grid-template-columns: 1fr;
        grid-template-areas: 'corpo' 'contacte' 'llengua' 'troba';
        padding-block: 0;
        margin-block: 24px;
    }
    .footer_main__container h3 {
        padding-block: 16px 8px;
        font-size: var(--body_l);
    }
    .footer_main__container h3 span {
        font-size: var(--body_l);
    }

    .footer_main__container span.drowpdown_icona {
        display: grid;
        place-content: center;
    }

    .js_desplegable + ul {
        display: none;
    }
    .js_desplegable {
        cursor: pointer;
        font-size: 1.8rem;
        color: var(--clr_trans_20);
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
    }
    .open .js_desplegable + ul {
        display: block;
    }
    .open .drowpdown_icona {
        transform: rotate(180deg);
    }
    .footer_main__boto:last-child {
        flex-basis: 100%;
        margin-top: 15px;
    }
    .footer_main__boto a {
        font-size: var(--body_l);
        height: 40px;
        width: 190px;
    }
    .footer_main__troba {
        display: block;
    }
    .footer_main__troba ul {
        flex-wrap: wrap;
    }
    .footer_main__troba li {
        margin-bottom: 10px;
    }
    .footer_main__troba h3 {
        padding-block: 10px 18px;
    }
    .footer_main__troba.open h3:after,
    .footer_main__troba h3:after {
        content: none;
    }
    .footer_post__container {
        display: block;
    }
    .footer_post__container div {
        flex-wrap: wrap;
    }
    .footer_post__container span {
        line-height: 1.2;
        font-size: var(--body_m);
    }
    .footer_post__copy {
        font-size: var(--body_m);
        padding-block: 10px;
    }
}
@media (max-width: 479px) {
    .footer_main__corpo ul {
        -moz-column-count: auto;
        column-count: auto;
    }
    .footer_main__troba {
        display: block;
    }
    .footer_main__troba ul {
        flex-wrap: wrap;
        width: 100%;
    }
    .footer_main__troba li {
        width: 100%;
        margin-bottom: 10px;
    }
    .footer_main__troba a {
        width: 100%;
    }
    .footer_main__boto a.footer_main__boto_ajuda {
        width: 100%;
    }
}
